import React from 'react';
import Modules from './Modules';
import Generic from './Generic';

function App() {
  return (
    <div style={{height:'100vh'}}>
      <Modules/>

    </div>
  );
}

export default App;
